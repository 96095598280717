<template>
  <section class="mb-15">
    <v-container>
      <v-row
        no-gutters
        dense
      >
        <v-col
          v-if="!$vuetify.breakpoint.mobile"
          align="center"
          cols="12"
          md="6"
        >
          <v-sheet
            max-width="85%"
            color="transparent"
            class="image-slide-sheet"
          >
            <v-slide-group
              v-model="img"
              show-arrows
              center-active
              mandatory
            >
              <v-slide-item
                style="transform: rotate(-90deg)"
                v-for="n in product.product_images"
                :key="n.id"
                v-slot="{ active, toggle }"
              >
                <v-card
                  @click="selectImg(n.src)"
                  :style="active ? 'border: 1px solid #ec6a23' : ''"
                  class="overflow-hidden elevation-3 ma-1"
                  width="70"
                  height="70"
                >
                  <v-img
                    @click="toggle"
                    :src="$store.state.imagePath + n.src"
                    min-height="100%"
                  />
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
          <v-dialog
            class="white"
            width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-sheet
                v-on="on"
                v-bind="attrs"
                width="450"
                height="450"
                rounded="lg"
                class="overflow-hidden elevation-4 ml-15"
              >
                <v-img
                  :src="$store.state.imagePath + selectedImg"
                  min-height="100%"
                />
              </v-sheet>
            </template>
            <v-card flat>
              <v-img
                :src="$store.state.imagePath + selectedImg"
                :lazy-src="$store.state.imagePath + selectedImg"
                max-width="100%"
                max-height="500"
                contain
              />
            </v-card>
          </v-dialog>
        </v-col>
        <v-col v-else>
          <v-carousel
            hide-delimiters
            v-model="model"
          >
            <v-carousel-item
              v-for="color in 5"
              :key="color"
              src="@/assets/product.png"
            />
          </v-carousel>
        </v-col>
        <v-col
          class="pt-16"
          cols="12"
          md="6"
        >
          <h1 class="primary--text text-h4">
            {{ product.product_name }}
          </h1>
          <h6 class="text-h6">Código: {{ product.product_reference }}</h6>
          <v-chip
            class="px-15 overline"
            color="error"
            small
            >Promoção</v-chip
          >

          <v-row
            class="mt-5"
            dense
          >
            <v-col
              cols="12"
              md="9"
            >
              <v-select
                v-model="budget_product.products[0].print_name"
                placeholder="Tipo de gravação"
                :items="this.product.product_print_types"
                item-text="name"
                item-value="name"
                rounded
                filled
                hide-details
                dense
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              cols="4"
              md="3"
            >
              <v-text-field
                placeholder="Qtd"
                v-mask="['########']"
                v-model="budget_product.products[0].row[0].quantity"
                rounded
                dense
                hide-details
                filled
              />
            </v-col>
            <v-col
              cols="4"
              md="3"
            >
              <v-text-field
                placeholder="Qtd"
                v-mask="['########']"
                v-model="budget_product.products[0].row[1].quantity"
                rounded
                dense
                hide-details
                filled
              />
            </v-col>
            <v-col
              cols="4"
              md="3"
            >
              <v-text-field
                placeholder="Qtd"
                v-model="budget_product.products[0].row[2].quantity"
                rounded
                dense
                hide-details
                filled
              />
            </v-col>
          </v-row>

          <h6 class="text-h6 mt-5">Cor</h6>
          <v-item-group v-model="budget_product.products[0].color">
            <v-container>
              <v-item
                :value="color"
                v-for="(color, idx) in this.product.colors"
                :key="idx"
                v-slot="{ active, toggle }"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      class="mx-2"
                      @click="toggle"
                      style="border: 2px solid var(--v-primary-base)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <div @click="addColorProduct(color)">
                        <v-img
                          :src="$store.state.imagePath + color.image_color"
                        >
                          <v-icon
                            v-if="active"
                            color="white"
                            >fas fa-check</v-icon
                          >
                        </v-img>
                      </div>
                    </v-avatar>
                  </template>
                  <span>{{ color.name }}</span>
                </v-tooltip>
              </v-item>
            </v-container>
          </v-item-group>

          <div>
            <!-- <v-btn :width="$vuetify.breakpoint.mobile ? '100%' : ''" color="primary" class="mr-10 my-5" rounded v-on:click="solicited()">Solicitar orçamento</v-btn> -->
            <v-btn
              :width="$vuetify.breakpoint.mobile ? '100%' : ''"
              rounded
              color="primary"
              @click="addShoppingCart"
              >Adicionar ao carrinho</v-btn
            >
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          xl="8"
        >
          <Title title="Descrição" />
          <p>
            {{ product.product_description }}
          </p>
          <v-simple-table class="my-3">
            <template v-slot:default>
              <thead>
                <tr class="primary">
                  <th
                    style="border-right: 1px solid white"
                    class="text-center white--text"
                  >
                    Tipo de gravação
                  </th>
                  <th
                    style="border-right: 1px solid white"
                    class="text-center white--text"
                  >
                    Medidas para gravação
                  </th>
                  <th
                    style="border-right: 1px solid white"
                    class="text-center white--text"
                  >
                    Dimensão do produto
                  </th>
                  <th
                    style="border-right: 1px solid white"
                    class="text-center white--text"
                  >
                    Profundidade
                  </th>
                  <th
                    style="border-right: 1px solid white"
                    class="text-center white--text"
                  >
                    Energia
                  </th>
                  <th class="text-center white--text">Peso</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, idx) in product.product_print_types"
                  :key="idx"
                >
                  <td class="text-center">{{ item.name }}</td>
                  <td class="text-center">
                    <ul>
                      <li>
                        Altura: {{ product.product_print_height | checkEmpty }}
                        {{ product.product_print_measure }}
                      </li>
                      <li>
                        Largura: {{ product.product_print_width | checkEmpty }}
                        {{ product.product_print_measure }}
                      </li>
                    </ul>
                  </td>
                  <td class="text-center">
                    <ul>
                      <li>Altura: {{ product.product_height | checkEmpty }}</li>
                      <li>Largura: {{ product.product_width | checkEmpty }}</li>
                      <li>
                        Diâmetro: {{ product.product_diameter | checkEmpty }}
                      </li>
                    </ul>
                  </td>
                  <td class="text-center">
                    {{ product.product_depth | checkEmpty }}
                  </td>
                  <td class="text-center">
                    {{ product.product_energy | checkEmpty }}
                  </td>
                  <td class="text-center">{{ product.product_weight }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <ul style="list-style: disc">
            <li>
              As medidas dos produtos são aproximadas, podem ocorrer pequenas
              variações de tamanho.
            </li>
            <li>
              Imagens meramente ilustrativas. Podem ocorrer variações de
              tonalidades entre os produtos, dependendo da configuração de cada
              monitor/tela.
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>

    <!--------------------------------------------------------------------- 
        Funcionalidade de avaliação de produto temporariamente desabilitada START
    ----------------------------------------------------------------------->
    <!-- <div class="grey lighten-4">
        <v-container>
            <v-row>
                <v-col cols="8">
                    <Title title="Avaliações" />
                    <span v-for="n in 5" :key="n">
                        <v-icon color="primary" class="mx-1" small>
                            fas fa-star
                        </v-icon>
                    </span>
                    <h3 class="text-h3 secondary--text">
                        3,5
                    </h3>

                    <v-list class="transparent" three-line>
                        <template v-for="(item, index) in 2">
                            <v-list-item :key="index">
                                <v-list-item-avatar>
                                    <v-img src="https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80" />
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        <div class="text-subtitle-2 font-weight-bold secondary--text">
                                            Nome do ser humano
                                        </div>
                                        <div class="text-caption secondary--text">
                                            20/10/2020
                                        </div>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span v-for="n in 5" :key="n">
                                            <v-icon color="primary" style="margin: 0 1px" x-small>
                                                far fa-star
                                            </v-icon>
                                        </span>
                                    </v-list-item-subtitle>                                    
                                    <p>
                                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam mollitia ipsam suscipit modi culpa? Dolores sed enim accusantium magni, officia magnam aperiam aut recusandae ut quaerat tempore ex maiores reprehenderit.
                                    </p>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                    
                    <Title title="Avalie este produto" />
                    <span v-for="n in 5" :key="n">
                        <v-icon color="primary" class="mx-1 mb-5" small>
                            far fa-star
                        </v-icon>
                    </span>
                    <v-textarea
                        placeholder="O que achou deste produto?"
                        auto-grow
                        filled
                        rounded
                        rows="8"
                    />
                    <v-btn color="primary" rounded>
                        Enviar
                        <v-icon right>fas fa-paper-plane</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div> -->
    <!--------------------------------------------------------------------- 
        Funcionalidade de avaliação de produto temporariamente desabilitada FINISH
    ----------------------------------------------------------------------->

    <!--------------------------------------------------------------------- 
        Funcionalidade de listagem de produtos relacionados temporariamente desabilitada START
    ----------------------------------------------------------------------->
    <v-container>
      <Title title="Produtos relacionados" />
      <v-row>
        <v-col
          v-for="(product, idx) in similarProducts"
          :key="idx"
          cols="6"
          md="3"
        >
          <ProductCard
            :minHeight="$vuetify.breakpoint.mobile ? 200 : 370"
            :product="product"
          />
        </v-col>
      </v-row>
    </v-container>
    <!--------------------------------------------------------------------- 
        Funcionalidade de listagem de produtos relacionados temporariamente desabilitada FINISH
    ----------------------------------------------------------------------->
  </section>
</template>

<script>
export default {
  components: {
    Title: () => import('@/components/web/layout/widgets/Title'),
    ProductCard: () => import('@/components/web/layout/widgets/ProductCard'),
  },
  data: () => ({
    img: null,
    product: {},
    similarProducts: [],
    selected_color_id: null,
    selectedImg: null,
    colors: [],
    print: null,
    color_selected: null,
    quantity1: null,
    quantity2: null,
    quantity3: null,
    budget_product: {
      products: [
        {
          row: [{ quantity: null }, { quantity: null }, { quantity: null }],
        },
      ],
    },
    shopCart: [],
  }),

  filters: {
    checkEmpty(value) {
      return value ? value : 'Não informado'
    },
  },

  methods: {
    getProduct() {
      this.$api
        .get(`/products/site/${this.$route.params.id}`)
        .then((res) => {
          this.product = res.data.data

          if (this.product.product_categories.length > 0) {
            this.getSimilarProducts()
          }

          this.$store.commit('setBreadcrumbs', [
            { title: 'Produtos', route: '/products' },
            {
              title: res.data.data.product_name,
              route: `/products/${res.data.data.id}`,
            },
          ])
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.selectedImg = this.product.product_images[0].src
          this.product.product_images.map((img) => {
            this.colors.push(img.color)
          })
        })
    },
    getSimilarProducts() {
      this.$api
        .get(
          `products/site/category/${this.product.product_categories[0].name}/type/similar/current/${this.product.id}`
        )
        .then((res) => {
          this.similarProducts = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    selectImg(src) {
      this.selectedImg = src
    },

    solicited: function () {
      this.budget_product.client_id = null
      this.budget_product.seller_id = null
      this.budget_product.products[0].product_id = this.$route.params.id

      this.$api
        .post(`/products/site/budget`, this.budget_product)
        .then((res) => {
          console.log('sucess', res)
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.selectedImg = this.product.product_images[0].src
          this.product.product_images.map((img) => {
            this.colors.push(img.color)
          })
        })
    },
    addColorProduct(obj) {
      this.selected_color_id = obj.id
    },
    addShoppingCart() {
      // this.budget_product.client_id = null
      // this.budget_product.seller_id = null
      this.budget_product.products[0].product_id = parseInt(
        this.$route.params.id
      )
      this.budget_product.products[0].color_id = this.selected_color_id
      this.budget_product.products[0].name = this.product.product_name
      this.budget_product.products[0].reference = this.product.product_reference
      this.budget_product.products[0].image = this.product.product_images[0].src

      for (let j = 0; j < this.product.product_print_types.length; j++) {
        if (
          this.product.product_print_types[j].name ==
          this.budget_product.products[0].print_name
        ) {
          this.budget_product.products[0].print_id =
            this.product.product_print_types[j].id
        }
      }

      this.budget_product.products[0].print = this.product.print_name

      //adicionando no carrinho
      this.addCart(this.budget_product.products[0])
    },
    addCart(item) {
      let obj_cart = item

      //verifica se esta vazio
      if (this.shopCart.length <= 0) {
        this.shopCart.push(obj_cart)
        this.$router.push('/budget/cart')
      } else {
        //Verifica se já existe o item no carrinho
        let check = false

        this.shopCart.map((item) => {
          //Verificando em caso de poder adicionar produtos de cores diferentes
          // if(item.product_id == obj_cart.product_id && item.color_id === obj_cart.color_id){
          //     check = true
          // }

          //Verificação basica, produtos com o mesmo id porém em relação às cores tanto faz.
          if (item.product_id == obj_cart.product_id) {
            check = true
          }
        })

        //Se não existir então ele adiciona
        if (check == false) {
          this.shopCart.push(obj_cart)
          this.$router.push('/budget/cart')
        } else {
          this.errorMessage()
        }
      }
    },
    errorMessage() {
      this.$store.commit('setModal', {
        show: true,
        component: 'error',
        text: 'Ops! Já possui um produto desse em seu carrinho.',
        caption:
          'Remova o produto do carrinho e tente novamente. Ou, caso queira um produto de cores diferentes e divididos em uma certa quantidade, entre em contato com um de nossos representantes.',
        confirm: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
  },
  created() {
    const Cart = JSON.parse(localStorage.getItem('Cart'))
    if (Cart.length > 0) {
      this.shopCart = Cart
    } else {
      this.shopCart = []
    }
  },
  mounted() {
    this.getProduct()
  },
  beforeDestroy() {
    localStorage.setItem('Cart', JSON.stringify(this.shopCart))
  },
}
</script>

<style lang="scss">
.v-slide-group__next i,
.v-slide-group__prev i {
  color: $primary !important;
}
.image-slide-sheet {
  position: relative;
  left: -45%;
  top: 50%;
  transform: rotate(90deg);
}

@media screen and (min-width: 1400px) {
  .image-slide-sheet {
    position: relative;
    left: -30%;
    top: 78%;
    transform: rotate(90deg);
  }
}
</style>
